exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dog-js": () => import("./../../../src/pages/dog.js" /* webpackChunkName: "component---src-pages-dog-js" */),
  "component---src-pages-fortune-index-js": () => import("./../../../src/pages/fortune/index.js" /* webpackChunkName: "component---src-pages-fortune-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-zodiac-index-js": () => import("./../../../src/pages/zodiac/index.js" /* webpackChunkName: "component---src-pages-zodiac-index-js" */),
  "component---src-pages-zodiac-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-animals-dog-index-mdx": () => import("./../../../src/pages/zodiac/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/animals/dog/index.mdx" /* webpackChunkName: "component---src-pages-zodiac-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-animals-dog-index-mdx" */),
  "component---src-pages-zodiac-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-animals-dragon-index-mdx": () => import("./../../../src/pages/zodiac/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/animals/dragon/index.mdx" /* webpackChunkName: "component---src-pages-zodiac-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-animals-dragon-index-mdx" */),
  "component---src-pages-zodiac-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-animals-goat-index-mdx": () => import("./../../../src/pages/zodiac/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/animals/goat/index.mdx" /* webpackChunkName: "component---src-pages-zodiac-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-animals-goat-index-mdx" */),
  "component---src-pages-zodiac-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-animals-horse-index-mdx": () => import("./../../../src/pages/zodiac/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/animals/horse/index.mdx" /* webpackChunkName: "component---src-pages-zodiac-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-animals-horse-index-mdx" */),
  "component---src-pages-zodiac-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-animals-monkey-index-mdx": () => import("./../../../src/pages/zodiac/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/animals/monkey/index.mdx" /* webpackChunkName: "component---src-pages-zodiac-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-animals-monkey-index-mdx" */),
  "component---src-pages-zodiac-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-animals-ox-index-mdx": () => import("./../../../src/pages/zodiac/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/animals/ox/index.mdx" /* webpackChunkName: "component---src-pages-zodiac-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-animals-ox-index-mdx" */),
  "component---src-pages-zodiac-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-animals-pig-index-mdx": () => import("./../../../src/pages/zodiac/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/animals/pig/index.mdx" /* webpackChunkName: "component---src-pages-zodiac-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-animals-pig-index-mdx" */),
  "component---src-pages-zodiac-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-animals-rabbit-index-mdx": () => import("./../../../src/pages/zodiac/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/animals/rabbit/index.mdx" /* webpackChunkName: "component---src-pages-zodiac-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-animals-rabbit-index-mdx" */),
  "component---src-pages-zodiac-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-animals-rat-index-mdx": () => import("./../../../src/pages/zodiac/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/animals/rat/index.mdx" /* webpackChunkName: "component---src-pages-zodiac-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-animals-rat-index-mdx" */),
  "component---src-pages-zodiac-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-animals-rooster-index-mdx": () => import("./../../../src/pages/zodiac/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/animals/rooster/index.mdx" /* webpackChunkName: "component---src-pages-zodiac-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-animals-rooster-index-mdx" */),
  "component---src-pages-zodiac-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-animals-snake-index-mdx": () => import("./../../../src/pages/zodiac/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/animals/snake/index.mdx" /* webpackChunkName: "component---src-pages-zodiac-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-animals-snake-index-mdx" */),
  "component---src-pages-zodiac-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-animals-tiger-index-mdx": () => import("./../../../src/pages/zodiac/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/animals/tiger/index.mdx" /* webpackChunkName: "component---src-pages-zodiac-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-animals-tiger-index-mdx" */)
}

